exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[name]-tsx": () => import("./../../../src/pages/news/[name].tsx" /* webpackChunkName: "component---src-pages-news-[name]-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-notification-tsx": () => import("./../../../src/pages/notification.tsx" /* webpackChunkName: "component---src-pages-notification-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-category-tsx": () => import("./../../../src/pages/product_category.tsx" /* webpackChunkName: "component---src-pages-product-category-tsx" */),
  "component---src-pages-products-[name]-tsx": () => import("./../../../src/pages/products/[name].tsx" /* webpackChunkName: "component---src-pages-products-[name]-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-pages-refund-tsx": () => import("./../../../src/pages/refund.tsx" /* webpackChunkName: "component---src-pages-refund-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-tokushohou-tsx": () => import("./../../../src/pages/tokushohou.tsx" /* webpackChunkName: "component---src-pages-tokushohou-tsx" */)
}

